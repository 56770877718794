import React from "react";
import { Route, Routes } from "react-router-dom";
import { COMMON_ROUTES } from "../Path/CommonRoutes";
import HomeContainer from "../../Container/HomeContainer/HomeContainer";
import AboutUsContainer from "../../Container/AboutUsContainer/AboutUsContainer";
import Contact from "../../Page/Desktop/Contact/Contact";
import PrivacyPolicy from "../../Page/Desktop/PrivacyPolicy/PrivacyPolicy";
import Claims from "../../Page/Desktop/Claims/Claims";
import Disclaimer from "../../Page/Desktop/Disclaimer/Disclaimer";
import Complaint from "../../Page/Desktop/Complaint/Complaint";
import FAQs from "../../Page/Desktop/FAQs/FAQs";
import CancellationRefund from "../../Page/Desktop/Cancellation&Refund/CancellationRefund";
import HealthProductPage from "../../Page/Desktop/HealthProductPage/HealthProductPage";
import TermProductPage from "../../Page/Desktop/TermProductPage/TermProductPage";
import CarProductPage from "../../Page/Desktop/CarProductPage/CarProductPage";
import TWProductPage from "../../Page/Desktop/TWProductPage/TWProductPage";
import TermsConditions from "../../Page/Desktop/TermsConditions/TermsConditions";

function CommonRoutes() {
  return (
    <>
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<AboutUsContainer />} />
        <Route path={COMMON_ROUTES.CONTACT_US} element={<Contact />} />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route path={COMMON_ROUTES.CLAIMS} element={<Claims />} />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route path={COMMON_ROUTES.COMPLAINT} element={<Complaint />} />
        <Route path={COMMON_ROUTES.FAQ} element={<FAQs />} />
        <Route path={COMMON_ROUTES.TERM_AND_CONDTION} element={<TermsConditions />} />
        <Route
          path={COMMON_ROUTES.REFUND_AND_CANCELLATION}
          element={<CancellationRefund />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<TWProductPage />} />
      </Routes>
    </>
  );
}

export default CommonRoutes;
